import skryv from '@skryv/core-ng1';
import { theme, translation, navigation, search } from '@skryv/core-ng1';

import flCustomizations from './customizations';

import nl_BE from './translations/nl_BE.po';
import './translations/template.html';
import logo from './theme/assets/images/logo.png';

const skrPages = skryv.constants.navigation.pageNames;

theme
  .logo(logo, 'Formulierenloket');

translation
  .add('nl_BE', nl_BE)
  .locale('nl_BE');

navigation
  .page(skrPages.DOSSIER, {
    extend: true,
    views: {
      content: 'customDossier'
    }
  })
  .tab('search','tasks', {
    extend: true,
    authorizationKey: 'SEARCH_TASKS'
  })
  .tab('search','documents', {
    extend: true,
    authorizationKey: 'SEARCH_DOCUMENTS'
  })
  .setTabsForGroup('search',['dossiers','tasks', 'historicTasks', 'documents']);

search
  .defaultForDashboardMyTasks({
    name: 'Default view',
    searchTerm: '',
    columns: [
      'task.urgency',
      'dossier.label',
      'task.name',
      'task.created'
    ],
    filters: [],
    sort: {
      criterion: 'task.created',
      desc: false
    }
  });

skryv
  .customize(flCustomizations)
  .customize(theme)
  .customize(translation)
  .customize(navigation)
  .customize(search)
  .initialize(document.getElementById('root'));
